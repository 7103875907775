@import 'scss/global';

.error-page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        background-color: $color--light;
        width: min(600px, 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px;
    }

    &__logo svg {
        width: 100px;
        height: 100px;
        margin-bottom: -50px;
    }

    &__title {
        font-size: 165px;
        font-weight: 700;
        margin: 0;
        color: #262626;

        span:nth-child(2) {
            color: #00b7ff;
        }
    }

    a {
        text-decoration: none;
    }
}
