@import 'scss/global';

$width: 15px;
$height: 15px;

$bounce_height: 20px;

.wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .text {
        color: $color--primary;
        display: inline-block;
    }

    .bounceball {
        position: relative;
        display: inline-block;
        height: 37px;
        width: $width;
        &:before {
            position: absolute;
            content: '';
            display: block;
            top: 0;
            width: $width;
            height: $height;
            border-radius: 50%;
            background-color: $color--primary;
            transform-origin: 50%;
            animation: bounce 500ms alternate infinite ease;
        }
    }

    @keyframes bounce {
        0% {
            top: $bounce_height;
            height: 5px;
            border-radius: 60px 60px 20px 20px;
            transform: scaleX(2);
        }
        35% {
            height: $height;
            border-radius: 50%;
            transform: scaleX(1);
        }
        100% {
            top: 0;
        }
    }
}
