@font-face {
    font-family: 'Inter-bold';
    font-style: normal;
    src: local('Inter Bold'), local('Inter-Bold'),
        url('../assets/fonts/Inter/Inter-Bold.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-regular';
    font-style: normal;
    src: local('Inter Regular'), local('Inter-Regular'),
        url('../assets/fonts/Inter/Inter-Regular.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-medium';
    font-style: normal;
    src: local('Inter Medium'), local('Inter-Medium'),
        url('../assets/fonts/Inter/Inter-Medium.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-semibold';
    font-style: normal;
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
        url('../assets/fonts/Inter/Inter-SemiBold.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Inter-light';
    font-style: normal;
    src: local('Inter Light'), local('Inter-Light'),
        url('../assets/fonts/Inter/Inter-Light.otf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Be Vietnam Pro-Light';
    font-style: normal;
    src: url('../assets/fonts/BeVietnamPro/BeVietnamPro-Light.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Be Vietnam Pro-Medium';
    font-style: normal;
    src: url('../assets/fonts/BeVietnamPro/BeVietnamPro-Medium.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Be Vietnam Pro-SemiBold';
    font-style: normal;
    src: url('../assets/fonts/BeVietnamPro/BeVietnamPro-SemiBold.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Be Vietnam Pro-Regular';
    font-style: normal;
    src: url('../assets/fonts/BeVietnamPro/BeVietnamPro-Regular.ttf');
    font-display: swap;
}

$font-regular: 'Inter-regular';
$font-semibold: 'Inter-semibold';
$font-bold: 'Inter-bold';
$font-light: 'Inter-light';
$font-medium: 'Inter-medium';

$be-vietnam-pro-light: 'Be Vietnam Pro-Light';
$be-vietnam-pro-medium: 'Be Vietnam Pro-Medium';
$be-vietnam-pro-semibold: 'Be Vietnam Pro-SemiBold';
$be-vietnam-pro-regular: 'Be Vietnam Pro-Regular';
